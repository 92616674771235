<template>
  <div id="res-info">
    <div ref="echarts" class="chart-box"></div>
  </div>
</template>


<script>
import * as echarts from 'echarts'
export default {
  props: {
    countList: {
      type: Array,
      default () {
        return []
      }
    },
    resCount: {
      type: Number | String,
      default: 1000
    },
    countName: {
      type: String,
      default: '人员总数'
    },
    unit: {
      type: String,
      default: '人'
    }
  },
  data () {
    return {
      chartPie: null
    }
  },
  activated () {
    // 由于给echart添加了resize事件, 在组件激活时需要重新resize绘画一次, 否则出现空白bug
    if (this.chartPie) {
      this.chartPie.resize()
    }
  },
  watch: {
    countList: {
      // immediate: true,
      handler (val) {
        if (val) {
          this.initChartPie()
        } else {
          this.chartPie = echarts.init(this.$refs.echarts)
          this.chartPie.clear()
        }
      }
    }
  },
  created () {
    this.$nextTick(() => {
      this.initChartPie()
    })
  },
  methods: {
    resize (val) {
      let nowClientWidth = document.documentElement.clientWidth
      return val * (nowClientWidth / 750)
    },
    initChartPie () {
      // 图表配置项
      let that = this
      var option = {
        textStyle: {
          fontFamily: 'SourceHanSans'
        },
        legend: {
          icon: 'circle',
          data: this.countList.filter(item => item.name),
          itemWidth: this.resize(18),
          itemHeight: this.resize(18),
          orient: 'vertical',
          right: this.countList.length > 3 ? this.resize(30) : this.resize(100),
          top: this.resize(35),
          itemGap: this.resize(35),
          textStyle: {
            color: '#387FF5',
            fontSize: this.resize(28),
            rich: {
              value: {
                color: '#666',
                lineHeight: 20,
                fontSize: this.resize(28)
              }
            }
          },
          formatter: (name) => {
            let value, percent, showName
            this.countList.filter(item => {
              if (item.name === name) {
                value = item.value
                percent = item.percent
              }
            })
            if (this.countName == '场所总数') {
              showName = '{value|' + name + '}' + '\n' + value + '' + that.unit + ' | ' + percent
            } else {
              showName = '{value|' + name + '}' + '\n' + value + '' + that.unit
            }
            return showName
          }
        },
        tooltip: {
          trigger: 'item',
          formatter: '{a} <br/>{b}: {c} ({d}%)'
        },
        series: [
          {
            name: this.countName + '统计',
            type: 'pie',
            radius: ['50%', '60%'],
            right: this.countList.length > 3 ? this.resize(400) : this.resize(200),
            data: this.countList,
            itemStyle: {
              normal: {
                color: function (params) {
                  var colorList = ['#387FF5', '#23D87B', '#FFA93A', '#FF5555', '#FF69BE', '#41A3FF', '#23D87B', '#FFA93A', '#FF5555', '#FF69BE']
                  return colorList[params.dataIndex]
                }
              }
            },
            label: {
              show: false
            }
          }, {
            name: this.countName + '统计',
            type: 'pie',
            radius: [0, '50%'],
            right: this.countList.length > 3 ? this.resize(400) : this.resize(200),
            data: [
              { name: this.countName, value: this.resCount }
            ],
            itemStyle: {
              normal: {
                color: function (params) {
                  var colorList = ['transparent']
                  return colorList[params.dataIndex]
                }
              }
            },
            label: {
              normal: {
                show: true,
                position: 'center',
                color: '#28282F',
                formatter: `{c}\n{value|${that.countName}}`,
                fontWeight: 500,
                fontSize: this.resize(36),
                rich: {
                  value: {
                    color: '#387FF5',
                    lineHeight: 20,
                    fontSize: this.resize(26)
                  }
                }
              }
            }
          }
        ]
      }
      this.chartPie = echarts.init(this.$refs.echarts)
      this.chartPie.setOption(option)
      window.addEventListener('resize', () => {
        if (this.chartPie) {
          this.chartPie.resize()
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
#res-info {
  position: relative;
  width: 100%;
  height: 100%;
  .chart-box {
    height: 100%;
    z-index: 999;
  }
}
</style>
