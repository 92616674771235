<template>
  <div class="sex_box">
    <div class="sex_title">
      <p class="man_title">男性<span>{{man}}</span>人</p>
      <p class="woman_title">女性<span>{{woman}}</span>人</p>
    </div>
    <div class="sex_progress">
      <div class="man_progress" :style="`width: ${manProgress}%`">
        <img :src="require('@/assets/img/icon/man-icon.png')" alt="" class="man_icon" />
        <p>{{manProgress}}%</p>
        <span></span>
      </div>
      <div class="woman_progress" :style="`width: ${womanProgress}%`">
        <img :src="require('@/assets/img/icon/woman-icon.png')" alt="" class="woman_icon" />
        <p>{{womanProgress}}%</p>
        <span></span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['man' , 'woman'],
  data () {
    return {
      manProgress: 50,
      womanProgress: 50
    }
  },
  watch: {
    man () {
      this.getProgress()
    }
  },
  methods: {
    getProgress () {
      let man = parseInt(this.man)
      let woman = parseInt(this.woman)
      let count = man + woman
      this.manProgress = man ? parseInt(Math.round(man / count * 10000) / 100) : 50
      this.womanProgress = 100 - this.manProgress
    }
  }
}
</script>
<style lang="scss" scoped>
.sex_box {
  padding: 30px;
  .sex_title {
    overflow: hidden;
    .man_title {float: left;}
    .woman_title {
      float: right;
      span {
        color: #23D87B !important;
      }  
    }
    .man_title, .woman_title {
      color: #333;
      font-size: 28px;
      margin: 0;
      span {
        color: #387FF5;
        font-weight: bold;
      }
    }
  }
  .sex_progress {
    width: 100%;
    border-radius: 3px;
    overflow: hidden;
    margin-top: 20px;
    .man_progress {
      float: left;
      background-color: #387FF5;
      text-align: right;
      p {
        margin: 10px 30px 0 0;
      }
      span {
        right: 0;
        border-bottom: 40px solid #fff;
        border-left: 20px solid transparent;
      }
      .man_icon {
        width: 20px;
        position: absolute;
        top: 10px;
        left: 20px;
      }
    }
    .woman_progress {
      float: right;
      background-color: #23D87B;
      p {
        margin: 10px 0 0 30px;
      }
      span {
        left: 0;
        border-top: 40px solid #fff;
        border-right: 20px solid transparent;
      }
      .woman_icon {
        width: 20px;
        position: absolute;
        top: 10px;
        right: 20px;
      }
    }
    .man_progress, .woman_progress {
      height: 40px;
      color: #fff;
      font-size: 24px;
      position: relative;
      span {
        width: 0;
        height: 0;
        position: absolute;
        top: 0;
      }
    }
  }
}
</style>