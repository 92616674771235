<template>
  <div id="line">
    <div ref="echarts" class="chart-box"></div>
  </div>
</template>


<script>
import * as echarts from 'echarts'
export default {
  props: {
    countList: {
      type: Array,
      default () {
        return []
      }
    },
    labelName: {
      type: Array,
      default () {
        return []
      }
    }
  },
  data () {
    return {
      chartLine: null
    }
  },
  activated () {
    // 由于给echart添加了resize事件, 在组件激活时需要重新resize绘画一次, 否则出现空白bug
    if (this.chartLine) {
      this.chartLine.resize()
    }
  },
  watch: {
    countList: {
      // immediate: true,
      handler (val) {
        if (val) {
          this.initchartLine()
        } else {
          this.chartLine = echarts.init(this.$refs.echarts)
          this.chartLine.clear()
        }
      }
    }
  },
  created () {
    this.$nextTick(() => {
      this.initchartLine()
    })
  },
  methods: {
    resize (val) {
      let nowClientWidth = document.documentElement.clientWidth
      return val * (nowClientWidth / 750)
    },
    initchartLine () {
      let colorList= ['#387FF5', '#23D87B', '#FFA93A']
      let option = {
        legend: {
          data: this.countList.map(item => item.name),
          top: this.resize(30),
          right: this.resize(20)
        },
        tooltip: {
          trigger: 'axis'
        },
        grid: {
          left: this.resize(80),
          right: this.resize(60),
          bottom: this.resize(70),
          top: this.resize(80)
        },
        xAxis: {
          type: 'category',
          splitLine: false,
          axisLine: {
            lineStyle: {
              color: '#ccc'
            }
          },
        axisLabel: {
          interval:0, //强制显示文字
          show: true,
        },
        data: this.labelName
        },
        yAxis: {
          type: 'value',
          splitLine: false,
          axisLine: {
            show: true,
            lineStyle: {
              color: '#ccc'
            }
          },
        },
        series: this.countList.map((item, i) => {
          return {
            name: item.name,
            data: item.value,
            type: 'line',
            itemStyle: {
              normal: {
                color: colorList[i],//折线点的颜色
                lineStyle: {
                  color: colorList[i]//折线的颜色
                }
              }
            },
            lineStyle: {
              normal: {
                shadowColor: colorList[i],
                shadowOffsetY: 4.5,
                shadowBlur: 5
              }
            },
            smooth: true
          }
        })
      };
      // 图表配置项
      this.chartLine = echarts.init(this.$refs.echarts)
      this.chartLine.setOption(option)
      window.addEventListener('resize', () => {
        if (this.chartLine) {
          this.chartLine.resize()
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
#line {
  position: relative;
  width: 100%;
  height: 100%;
  .chart-box {
    height: 100%;
    z-index: 999;
  }
}
</style>