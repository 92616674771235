<template>
  <div>
    <van-popup v-model="orgShow" position="bottom"  >
      <van-picker title="请选择" show-toolbar :columns="orgList" value-key="name" @confirm="orgConfirm" @cancel="orgShow = false"  />
    </van-popup>
    <div class="topOrgBox">
      <p @click="orgShow = !orgShow" :style="{color:orgShow?'#387FF5':'#ccc'}" class="topOrg">
        {{orgName}}
        <img :src="require(`@/assets/img/${orgShow?'pull-down-select':'pull-down'}.png`)" alt="" class="pull-icon">
      </p>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      orgShow: false,
      orgName: '全社区数据',
      selectOrgId: 0,
      orgList: [
        {name: '全社区数据', id: 0}
      ]
    }
  },
  created () {
    let depth= this.$globalData.userInfo.depth
    if (depth == 4){
      this.getCommunityList()
    }else {
      this.getGridList()
    }
  },
  methods: {
    orgConfirm (val) {
      this.orgName = val.name
      this.selectOrgId = val.id
      this.isStreet = val.value == 0
      this.$emit('orgChange', this.selectOrgId, this.isStreet)
      this.orgShow =  false
    },
    getGridList () {
      this.$http({
        url: this.$http.adornUrl('/wxapp/grid/info/listByUser'),
        method: 'post',
        params: this.$http.adornParams({
          community: this.$globalData.userInfo.orgId
        })
      }).then(({data}) => {
        if (data && data.code === 0) {
          this.orgList.push(...data.grids)
          console.log(this.orgList);
        } else {
          this.$toast.fail(data.msg)
        }
      })
    },

    getCommunityList () {
      this.$http({
        url: this.$http.adornUrl('/wxapp/building/house/liveWhichTreeByDepth'),
        method: 'get',
        params: this.$http.adornParams({
          id: this.$globalData.userInfo.orgId,
          depth: -1
        })
      }).then(({data}) => {
        if (data && data.code === 0) {
          let list = data.liveWhichTree.map(item => {
            return {
              name: item.label,
              id: item.value
            }
          })
          if (this.haveStreet) {this.orgList.push({name: '本街道数据', id: 1})}
          this.orgList.push(...list)
        } else {
          this.$toast.fail(data.msg)
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.topOrgBox {
  padding: 20px 30px;
  .topOrg {
    width: 100%;
    height: 74px;
    text-align: center;
    line-height: 74px;
    margin: 0;
    font-size: 24px;
    border-radius: 10px;
    background-color: #fff;
    .pull-icon {
        width: 10px;
    }
  }
}
</style>
