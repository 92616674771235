<template>
  <div class="titleBox">
    <div class="title_content">
      <div class="blurBox"></div>
      {{title}}
      <span>{{tip}}</span>
    </div>
  </div>
</template>

<script>
export default {
props: ['title', 'tip']
}
</script>

<style lang="scss" scoped>
.titleBox {
  padding: 0 30px;
  height: 87px;
  .title_content {
    border-bottom: 2px solid #eee;
    height: 87px;
    line-height: 87px;
    position: relative;
    text-indent: 15px;
    font-size: 30px;
    font-weight: bold;
    .blurBox {
      float: left;
      width: 5px;
      height: 24px;
      background: #387FF5;
      border-radius: 3px;
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-12px);
    }
    span {
      float: right;
      font-size: 22px;
      color: #999;
      text-align: right;
    }
  }
}
</style>