<template>
  <div id="label-type">
    <div ref="echarts" class="chart-box"></div>
  </div>
</template>

<script>
import * as echarts from 'echarts'

export default {
  props: {
    showMore: {
      type: Boolean
    },
    labelName: {
      type: Array,
      default () {
        return []
      }
    },
    labelCount: {
      type: Array,
      default () {
        return []
      }
    },
    dataName: {
      type: String,
      default () {
        return '统计'
      }
    }
  },
  data () {
    return {
      chartBar: null
    }
  },
  activated () {
    // 由于给echart添加了resize事件, 在组件激活时需要重新resize绘画一次, 否则出现空白bug
    if (this.chartBar) {
      this.chartBar.resize()
    }
  },
  watch: {
    labelCount: {
      // immediate: true,
      handler (val) {
        if (val.length !== 0) {
          if (this.chartBar) {
            this.chartBar.clear() 
          }
          this.initChartBar()
        } else {
          this.chartBar = echarts.init(this.$refs.echarts)
          this.chartBar.clear()
        }
      }
    }
  },
  methods: {
    resize (val) {
      let nowClientWidth = document.documentElement.clientWidth
      return val * (nowClientWidth / 750)
    },
    changeFlag () {
      this.$emit('changeFlag', 3)
    },
    // 获取数据并给charts添加数据
    initChartBar () {
      let labelName = this.labelName
      let labelCount = this.labelCount
      let obj = {
        type: 'slider',
        show: this.labelName.length > 7,
        width: 10,
        maxValueSpan: 7,
        minValueSpan: 7,
        startValue: this.labelName.length > 7 ? this.labelName.length - 7 : 0,
        brushStyle: {
          color: '#F5F9FF',
          backgroundColor: '#F5F9FF',
          opacity: 1
        },
        brushSelect: false,
        yAxisIndex: [0]
      }
      if (this.labelName.length > 12) {
        obj['maxSpan'] = 12
      }
      var option = {
        dataZoom: [obj],
        grid: {
          left: '18%',
          right: '20%',
          bottom: '15%',
          top: '5%'
        },
        tooltip: {
          trigger: 'item',
          formatter: '{a} <br/>{b}: {c}'
        },
        xAxis: {
          type: 'value',
          splitLine: false,
          axisLine: {
            show: true,
            lineStyle: {
              color: '#ccc'
            }
          },
        },
        yAxis: {
          data: labelName,
          axisLine: {
            show: true,
            lineStyle: {
              color: '#ccc'
            }
          },
          axisLabel: {
            color: '#666666',
            fontSize: this.resize(22)
          },
          axisTick: {
            show: false
          }
        },
        series: [
          {
            showBackground: true,
            backgroundColor: '#F5F9FF',
            name: this.dataName,
            type: 'bar',
            data: labelCount,
            barWidth: this.resize(24),
            backgroundStyle: {
              borderRadius:[this.resize(20),this.resize(20), this.resize(20), this.resize(20)]
            },
            label: {
              show: true,
              position: 'right',
              offset: [0, this.resize(2)],
              formatter (params) {
                let per = params.value > 0 ? ((params.value / labelCount.reduce((a, b) => a+b)) * 100).toFixed(2) : 0
                return `${params.value}/${per}%`
              },
              fontStyle: {
                color: '#666666'
              }
            },
            itemStyle: {
              normal: {
                color: '#387FF5',
                barBorderRadius:[this.resize(20),this.resize(20), this.resize(20), this.resize(20)]
              }
            },
          }
        ]
      }
      this.chartBar = echarts.init(this.$refs.echarts)
      this.chartBar.setOption(option)
      window.addEventListener('resize', () => {
        if (this.chartBar) {
          this.chartBar.resize()
        }
      })
    }
  },
  mounted () {
    this.initChartBar()
  }
}
</script>

<style lang="scss" scoped>
div {
  height: 100%;
}
#label-type {
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 100%;
  // display: flex;
  // flex-direction: column;
  box-sizing: border-box;
  .chart-box {
    height: 100%;
    z-index: 999;
  }
}
</style>
