<template>
  <div class="box">
    <div class="left_count">
      <img class="count_img" :src="require('@/assets/img/statistics/user_count.png')" alt="">
      <p class="count_num"><span>{{typeCount}}</span>人</p>
      <p class="count_tip">社区总人数</p>
    </div>
    <div class="right_list">
      <ul>
        <li v-for="(item, index) in list" :key="item.label" @click="lookInfo(item.children)">
          <div class="left_dian"><span :class="'color' + (index % 4)"></span></div>
          <div class="right_text">
            <p>{{item.label}}</p>
            <span>{{item.num}}人{{item.per ? '|' + item.per + '%' : ''}}</span>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    list: {
      type: Array,
      default () {
        return []
      }
    },
    typeCount: {
      type: Number | String,
      default () {
        return 0
      }
    }
  },
  data() {
    return {
    }
  },
  methods: {
    lookInfo (childrenList) {
      this.$emit('lookInfo', childrenList)
    }
  }
}
</script>
<style lang="scss" scoped>
ul, li {list-style: none; margin: 0; padding:  0;}
.box {
  display: flex;
  padding-bottom: 35px;
  .left_count {
    width: 260px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    .count_img {
      width: 134px;
      height: 134px;
      margin-top: 80px;
      font-size: 0;
    }
    .count_num {
      width: 100%;
      font-size: 20px;
      color: #333;
      text-align: center;
      margin: -40px 0 0 0;
      span {
        display: inline-block;
        color: #4177FF;
        font-size: 36px;
      }
    }
    .count_tip {
      margin: -40px 0 0 0 ;
      font-size: 20px;
    }
  }
  .right_list {
    flex: 1;
    ul {
      display: flex;
      flex-wrap: wrap;
      li {
        width: 50%;
        display: flex;
        margin-top: 35px;
        .left_dian {
          width: 60px;
          display: flex;
          justify-content: center;
          align-items: center;
          span {
            width: 20px;
            height: 20px;
            border-radius: 50%;
          }
          .color0 {background-color: #387FF5;}
          .color1 {background-color: #23D87B;}
          .color2 {background-color: #FFC955;}
          .color3 {background-color: #FF5555;}
        }
        .right_text {
          flex: 1;
          p {
            font-size: 26px;
            margin: 0;
            color: #666;
          }
          span {
            display: inline-block;
            color: #999;
            font-size: 28px;
          }
        }
      }
    }
  }
}

</style>
